<template>
  <div class="help-wrap">
    <el-breadcrumb separator="/" class="path">
      <el-breadcrumb-item :to="{ path: '/' }" class="path-home"><i class="n el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item class="path-help">帮助</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="help" v-loading="loading">
      <div class="mc">
        <div class="j-module">
          <div class="jOneLevel">
            <span class="jClassi">HELP LIST</span>
            <div class="jHead">
              <span class="jDottedleft"></span>帮助列表<span class="jDottedRight"></span>
            </div>
          </div>
          <div class="jOneLevelarea" v-for="(item, index) in helpList" :key="index">
            <div class="jTwoLevel" data-num="0">
              <span class="jIconArrow"></span>
              <a @click="menuOther(item.class_id)" href="javascript:void(0)">{{ item.class_name }}</a>
            </div>
            <div class="jThreeLevel" v-if="item.child_list">
              <ul>
                <li :class="currentId == item.class_id ? 'active item-name' : 'item-name'"
                    v-for="cate in item.child_list"
                    :key="cate.class_id">
                  <a @click="menuOther(cate.class_id)"  href="javascript:void(0)">{{ cate.class_name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="list-other">
        <transition name="slide">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { helpCategorysList } from "@/api/cms/help";
export default {
  name: "help",
  components: {},
  data: () => {
    return {
      helpList: [],
      currentId: 0,
      loading: true
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    menuOther(id) {
      this.currentId = id;
      this.$router.push({ path: "/cms/help/listother-" + id });
    },
    getInfo() {
      helpCategorysList({
        app_module: "admin"
      })
        .then(res => {
          if (res.code == 0 && res.data) {
            this.$router.push({
              path: "/cms/help/listother-" + res.data[0].class_id
            });
            this.currentId = res.data[0].class_id;
            this.helpList = res.data;
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.help-wrap{
    background: #ffffff;
    .path{
        padding: 15px 0;
    }
}

.mc{padding: 15px 15px 0;background-color: #fcfcfc;}
.jOneLevel{overflow:hidden;text-align: center}
.jOneLevel .jClassi{font-size: 12px;color: #999;}
.jOneLevel .jHead{position: relative;font-size:18px;height: 30px;line-height: 30px;color: #000;padding: 0 20px 15px;border-bottom: 1px solid #e1e1e1;}
.jOneLevel .jHead .jDottedleft{position: absolute;display: block;left: 0px;top: 13px;height: 4px;width: 4px;background: url(//img14.360buyimg.com/cms/jfs/t3448/126/18152604/1049/6f71a7fc/57fd94c0N39f3eb9c.png) no-repeat;}
.jOneLevel .jHead .jDottedRight{position: absolute;display: block;right:0px;top: 13px;height: 4px;width: 4px;background: url(//img14.360buyimg.com/cms/jfs/t3448/126/18152604/1049/6f71a7fc/57fd94c0N39f3eb9c.png) no-repeat;}
.jTwoLevel{font-size: 14px;font-weight: bold;height: 45px;line-height: 45px;overflow: hidden;color: #e1e1e1;clear: both;text-align: center;border-bottom: dashed 1px #e1e1e1;position: relative;}
.jTwoLevel.jNoBorderBottm{border-bottom:0;}
.jIconArrow{display: block;width: 20px;height: 20px;cursor: pointer;position: absolute;right: 0;background: url(//img20.360buyimg.com/cms/jfs/t3442/274/61048783/1064/3b52ecb8/57fd94c0N66bbe258.png) no-repeat center;top: 13px;}
.current .jIconArrow{transform: rotate(90deg);}
.jTwoLevel a{display: block;color: #333;overflow: hidden;}
.jThreeLevel{overflow:hidden;_height:100%;}
.jThreeLevel li{height: 34px;line-height: 34px;overflow: hidden;}
.jThreeLevel a{display: block;height: 24px;margin-top: 5px;line-height: 24px;font-size: 12px;text-align: center;border-radius: 12px;}
.jThreeLevel a:hover{background-color: #efefef;color: #666;}

.help {
  display: flex;
  padding-bottom: 20px;
}
.list-other {
  margin-left: 20px;
  width: 80%;
}
</style>
